export const ORDER_ADDRESS_TYPE_PERSON = 'person'
export const ORDER_ADDRESS_TYPE_COMPANY = 'company'

export const ORDER_ADDRESS_TYPE_DEFAULT = ORDER_ADDRESS_TYPE_PERSON

export default {
  computed: {
    orderAddressTypeValues () {
      return [
        {
          id: ORDER_ADDRESS_TYPE_PERSON,
          title: this.$t('eshop.order_address_type.person')
        },
        {
          id: ORDER_ADDRESS_TYPE_COMPANY,
          title: this.$t('eshop.order_address_type.company')
        }
      ]
    }
  },
  methods: {
    orderAddressTypeById (id) {
      var item = id
      this.orderAddressTypeValues.forEach(function (addressType) {
        if (id === addressType.id) {
          item = addressType
        }
      })
      return item
    }
  }
}
