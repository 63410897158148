<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <!--app-button-delete @deleteRecord="deleteOrder"></app-button-delete-->
              <app-button-close route-name="eshopSubscriptionOrder_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>

      <div class="row">
        <div class="col-lg-8">
          <div class="card card-body">
            <table class="table table-striped table-bordered">
              <tbody>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.variableSymbol') }}</td>
                <td>{{ order.variableSymbol }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.email') }}</td>
                <td>{{ order.email }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.price') }}</td>
                <td>{{ order.price / 100 }}</td>
              </tr>
              <tr v-if="product">
                <td>{{ $t('eshopSubscriptionOrder.product') }}</td>
                <td>
                  <router-link
                    tag="a"
                    :to="{name: 'eshopSubscriptionProduct_edit', params: { id: order.product }}">
                    {{ product.name }}
                  </router-link>
                </td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.payment') }}</td>
                <td>{{ paymentType.title }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.paymentResult') }}</td>
                <td>{{ paymentResultType.title }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.notice') }}</td>
                <td>{{ order.notice }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.cardNumber') }}</td>
                <td>{{ order.cardNumber }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.count') }}</td>
                <td>{{ order.count }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.aboStartEdition') }}</td>
                <td>{{ order.aboStartEdition }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.sameDeliveryAddress') }}</td>
                <td>
                  <app-checkbox
                    v-model="order.sameDeliveryAddress"
                    disabled
                  >
                  </app-checkbox>
                </td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.giftSubscription') }}</td>
                <td>
                  <app-checkbox
                    v-model="order.giftSubscription"
                    disabled
                  >
                  </app-checkbox>
                </td>
              </tr>
              <tr v-if="aboStartEdition">
                <td>{{ $t('eshopSubscriptionOrder.aboStartEdition') }}</td>
                <td>{{ aboStartEdition.cisTit }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.feUser') }}</td>
                <td>
                  <router-link
                    v-if="order.feUserExpanded"
                    tag="a"
                    :to="{name: 'feUser_edit', params: { id: order.feUser }}">
                    {{ order.feUserExpanded.email }}
                  </router-link>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="card card-body">
            <h3>{{ $t('eshopSubscriptionOrder.invoiceAddress') }}</h3>
            <table class="table table-striped table-bordered">
              <tbody>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.type') }}</td>
                <td>{{ addressType.title }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.name') }}</td>
                <td>{{ order.address.name }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.surname') }}</td>
                <td>{{ order.address.surname }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.company') }}</td>
                <td>{{ order.address.company }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.ico') }}</td>
                <td>{{ order.address.ico }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.dic') }}</td>
                <td>{{ order.address.dic }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.icDph') }}</td>
                <td>{{ order.address.icDph }}</td>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.street') }}</td>
                <td>{{ order.address.street }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.city') }}</td>
                <td>{{ order.address.city }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.zip') }}</td>
                <td>{{ order.address.zip }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.country') }}</td>
                <td>{{ order.address.country }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="card card-body" v-if="!order.sameDeliveryAddress">
            <h3>{{ $t('eshopSubscriptionOrder.deliveryAddress') }}</h3>
            <table class="table table-striped table-bordered">
              <tbody>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.type') }}</td>
                <td>{{ deliveryAddressType.title }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.name') }}</td>
                <td>{{ order.deliveryAddress.name }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.surname') }}</td>
                <td>{{ order.deliveryAddress.surname }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.company') }}</td>
                <td>{{ order.deliveryAddress.company }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.ico') }}</td>
                <td>{{ order.deliveryAddress.ico }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.dic') }}</td>
                <td>{{ order.deliveryAddress.dic }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.icDph') }}</td>
                <td>{{ order.deliveryAddress.icDph }}</td>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.street') }}</td>
                <td>{{ order.deliveryAddress.street }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.city') }}</td>
                <td>{{ order.deliveryAddress.city }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.zip') }}</td>
                <td>{{ order.deliveryAddress.zip }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.address.country') }}</td>
                <td>{{ order.deliveryAddress.country }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card card-body">
            <h3>{{ $t('feUser.systemInfo') }}</h3>
            <table class="table table-striped table-bordered">
              <tbody>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.id') }}</td>
                <td>{{ order.id }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.created_at') }}</td>
                <td>{{ order.createdAt | prettyDateTime }}</td>
              </tr>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.modified_at') }}</td>
                <td>{{ order.modifiedAt | prettyDateTime }}</td>
              </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="px-3">
                <app-checkbox
                  v-model="order.subscriptionStatus.mailSent"
                  :label="$t('eshopSubscriptionOrder.subscriptionStatus.mailSent')"
                  disabled
                >
                </app-checkbox>
              </div>
            </div>
          </div>

          <div class="card card-body">
            <h3>{{ $t('eshopSubscriptionOrder.payments') }}</h3>
            <table class="table table-striped table-bordered payments">
              <thead>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.created_at') }}</td>
                <td>{{ $t('eshopSubscriptionOrder.paymentStatusType') }}</td>
                <td>{{ $t('eshopSubscriptionOrder.price') }}</td>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(payment, index) in order.latestPayments" :key="`item-${index}`">
                <td>{{ payment.createdAt | prettyDateTime }}</td>
                <td>{{ paymentStatusType(payment) }}</td>
                <td>{{ payment.price / 100 }}</td>
              </tr>
              </tbody>
              <tfoot v-if="order.latestPayments.length >= 10">
              <tr>
                <td colspan="3" class="text-right">
                  <button
                    type="button"
                    class="btn btn-inverse"
                    @click="morePayments"
                  >
                    {{ $t('eshopSubscriptionOrder.button.more') }}
                  </button>
                </td>
              </tr>
              </tfoot>
            </table>
          </div>

          <div class="card card-body">
            <h3>{{ $t('eshopSubscriptionOrder.history') }}</h3>
            <table class="table table-striped table-bordered history">
              <thead>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.created_at') }}</td>
                <td>{{ $t('eshopSubscriptionOrder.historyType') }}</td>
                <td>{{ $t('eshopSubscriptionOrder.historyDescription') }}</td>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(history, index) in order.latestHistory" :key="`item-${index}`">
                <td>{{ history.createdAt | prettyDateTime }}</td>
                <td>{{ historyType(history) }}</td>
                <td>{{ history.description }}</td>
              </tr>
              </tbody>
              <tfoot v-if="order.latestHistory.length >= 10">
              <tr>
                <td colspan="3" class="text-right">
                  <button
                    type="button"
                    class="btn btn-inverse"
                    @click="moreHistory"
                  >
                    {{ $t('eshopSubscriptionOrder.button.more') }}
                  </button>
                </td>
              </tr>
              </tfoot>
            </table>
          </div>

          <div class="card card-body">
            <h3>{{ $t('eshopSubscriptionOrder.abo.title') }}</h3>
            <table class="table table-striped table-bordered">
              <tbody>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.abo.pcZakaz') }}</td>
                <td>{{ order.abo.pcZakaz }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="card card-body">
            <h3>{{ $t('eshopSubscriptionOrder.aboPeriodik.title') }}</h3>
            <table class="table table-striped table-bordered">
              <tbody>
              <tr>
                <td>{{ $t('eshopSubscriptionOrder.aboPeriodik.customerId') }}</td>
                <td>{{ customerId }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import SubscriptionOrder from '../../model/eshop/SubscriptionOrder'
import ButtonClose from '../shared/ButtonClose'
import NotifyService from '../../services/NotifyService'
import Checkbox from '../form/Checkbox'
import PaymentTypeMixin from '../mixins/valueObject/eshop/PaymentTypeMixin'
import PaymentStatusTypeMixin from '../mixins/valueObject/eshop/PaymentStatusTypeMixin'
import PaymentResultTypeMixin from '../mixins/valueObject/eshop/PaymentResultTypeMixin'
import OrderAddressTypeMixin from '../mixins/valueObject/eshop/OrderAddressTypeMixin'
import HistoryTypeMixin from '../mixins/valueObject/eshop/HistoryTypeMixin'
import HistoryFilterModel from '../../model/eshop/HistoryFilter'
import PaymentFilterModel from '../../model/eshop/SubscriptionPaymentFilter'

export default {
  name: 'SubscriptionOrder',
  mixins: [
    PaymentTypeMixin,
    PaymentStatusTypeMixin,
    PaymentResultTypeMixin,
    OrderAddressTypeMixin,
    HistoryTypeMixin
  ],
  data () {
    return {
      error: null,
      order: this._.cloneDeep(SubscriptionOrder)
    }
  },
  computed: {
    product () {
      return this.$store.getters['eshopSubscriptionProduct/productById'](this.order.product)
    },
    aboStartEdition () {
      return this.$store.getters['eshopAboEdition/editionById'](this.order.aboStartEdition)
    },
    paymentType () {
      return this.paymentTypeById(this.order.payment)
    },
    paymentResultType () {
      return this.paymentResultTypeById(this.order.paymentResult)
    },
    addressType () {
      return this.orderAddressTypeById(this.order.address.type)
    },
    deliveryAddressType () {
      return this.orderAddressTypeById(this.order.deliveryAddress.type)
    },
    customerId () {
      return this.order.aboPeriodik.customerId || '-'
    }
  },
  components: {
    appModuleView: ModuleView,
    appButtonClose: ButtonClose,
    appCheckbox: Checkbox
  },
  methods: {
    getOrder () {
      this.$store.dispatch('eshopSubscriptionOrder/fetchOne', this.$route.params.id)
        .then(() => {
          this.order = this.$store.getters['eshopSubscriptionOrder/detail']
        })
    },
    deleteOrder () {
      this.$store.dispatch('eshopSubscriptionOrder/deleteRecord', this.order)
        .then(() => {
          if (this.$store.getters['eshopSubscriptionOrder/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/eshop/subscription/order')
          } else {
            NotifyService.setErrorMessage()
          }
        })
        .catch(error => console.log(error))
    },
    moreHistory () {
      const filter = this._.cloneDeep(HistoryFilterModel)
      filter.order = this.order.id

      this.$store.commit('eshopHistory/setPage', 1)
      this.$store.commit('eshopHistory/setFilter', filter)
      this.$router.push('/shop/history')
    },
    morePayments () {
      const filter = this._.cloneDeep(PaymentFilterModel)
      filter.order = this.order.id

      this.$store.commit('eshopSubscriptionPayment/setPage', 1)
      this.$store.commit('eshopSubscriptionPayment/setFilter', filter)
      this.$router.push('/shop/subscription/payment')
    },
    historyType (history) {
      return this.historyTypeById(history.type).title
    },
    paymentStatusType (payment) {
      return this.paymentStatusTypeById(payment.status).title
    }
  },
  mounted () {
    this.getOrder()
  }
}
</script>

<style lang="scss" scoped>
.table-bordered th:first-child {
  width: 200px;
}

table.history {
  table-layout: fixed;
}

table.history td:nth-child(1), table.history td:nth-child(2) {
  width: 25%;
}
</style>
