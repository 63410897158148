export const HISTORY_TYPE_ORDER_CREATED = 'order_created'
export const HISTORY_TYPE_PAYMENT_CREATED = 'payment_created'
export const HISTORY_TYPE_PAYMENT_GATEWAY_REDIRECT = 'payment_gateway_redirect'
export const HISTORY_TYPE_PAYMENT_GATEWAY_RETURN = 'payment_gateway_return'
export const HISTORY_TYPE_MAIL_SENT = 'mail_sent'
export const HISTORY_TYPE_PAYMENT_ABO_READY_TO_EXPORT = 'payment_abo_ready_to_export'
export const HISTORY_TYPE_PAYMENT_ABO_EXPORTED = 'payment_abo_exported'
export const HISTORY_TYPE_PAYMENT_ABO_EXPORT_FAILED = 'payment_abo_export_failed'
export const HISTORY_TYPE_PAYMENT_ABO_PROCESSED = 'payment_abo_processed'
export const HISTORY_TYPE_PAYMENT_ABO_NOT_PROCESSED = 'payment_abo_not_processed'
export const HISTORY_TYPE_PAYMENT_ABO_PERIODIK_READY_TO_EXPORT = 'payment_abo_periodik_ready_to_export'
export const HISTORY_TYPE_PAYMENT_ABO_PERIODIK_EXPORTED = 'payment_abo_periodik_exported'
export const HISTORY_TYPE_PAYMENT_ABO_PERIODIK_EXPORT_FAILED = 'payment_abo_periodik_export_failed'
export const HISTORY_TYPE_PAYMENT_ABO_PERIODIK_PROCESSED = 'payment_abo_periodik_processed'
export const HISTORY_TYPE_PAYMENT_ABO_PERIODIK_NOT_PROCESSED = 'payment_abo_periodik_not_processed'
export const HISTORY_TYPE_SUBSCRIPTION_ACTIVATED = 'subscription_activated'
export const HISTORY_TYPE_NEXT_PAYMENT_SET = 'next_payment_set'
export const HISTORY_TYPE_SMS_CODE_CREATED = 'sms_code_created'
export const HISTORY_TYPE_MO_SMS_DELIVERED = 'mo_sms_delivered'
export const HISTORY_TYPE_MT_SMS_CREATED = 'mt_sms_created'
export const HISTORY_TYPE_MT_SMS_SENT = 'mt_sms_sent'
export const HISTORY_TYPE_MT_SMS_SENT_ERROR = 'mt_sms_sent_error'
export const HISTORY_TYPE_TYPE_MT_SMS_STATUS_DELIVERED = 'mt_sms_status_delivered'
export const HISTORY_TYPE_PAYMENT_CANCELLED = 'payment_cancelled'
export const HISTORY_TYPE_PAYMENT_RESULT_SET = 'payment_result_set'
export const HISTORY_TYPE_CLOSED_SUBSCRIPTION_MAIL_3_DAYS = 'closed_subscription_mail_3_days'
export const HISTORY_TYPE_CLOSED_SUBSCRIPTION_MAIL_NEXT_DAY = 'closed_subscription_mail_next_day'
export const HISTORY_TYPE_NEXT_RECURRING_PAYMENT_MAIL = 'next_recurring_payment_mail'

export const HISTORY_TYPE_DEFAULT = HISTORY_TYPE_ORDER_CREATED

export default {
  computed: {
    historyTypeValues () {
      return [
        {
          id: HISTORY_TYPE_ORDER_CREATED,
          title: this.$t('eshop.history_type.order_created')
        },
        {
          id: HISTORY_TYPE_PAYMENT_CREATED,
          title: this.$t('eshop.history_type.payment_created')
        },
        {
          id: HISTORY_TYPE_PAYMENT_GATEWAY_REDIRECT,
          title: this.$t('eshop.history_type.payment_gateway_redirect')
        },
        {
          id: HISTORY_TYPE_PAYMENT_GATEWAY_RETURN,
          title: this.$t('eshop.history_type.payment_gateway_return')
        },
        {
          id: HISTORY_TYPE_MAIL_SENT,
          title: this.$t('eshop.history_type.mail_sent')
        },
        {
          id: HISTORY_TYPE_PAYMENT_ABO_READY_TO_EXPORT,
          title: this.$t('eshop.history_type.payment_abo_ready_to_export')
        },
        {
          id: HISTORY_TYPE_PAYMENT_ABO_EXPORTED,
          title: this.$t('eshop.history_type.payment_abo_exported')
        },
        {
          id: HISTORY_TYPE_PAYMENT_ABO_EXPORT_FAILED,
          title: this.$t('eshop.history_type.payment_abo_export_failed')
        },
        {
          id: HISTORY_TYPE_PAYMENT_ABO_PROCESSED,
          title: this.$t('eshop.history_type.payment_abo_processed')
        },
        {
          id: HISTORY_TYPE_PAYMENT_ABO_NOT_PROCESSED,
          title: this.$t('eshop.history_type.payment_abo_not_processed')
        },
        {
          id: HISTORY_TYPE_PAYMENT_ABO_PERIODIK_READY_TO_EXPORT,
          title: this.$t('eshop.history_type.payment_abo_periodik_ready_to_export')
        },
        {
          id: HISTORY_TYPE_PAYMENT_ABO_PERIODIK_EXPORTED,
          title: this.$t('eshop.history_type.payment_abo_periodik_exported')
        },
        {
          id: HISTORY_TYPE_PAYMENT_ABO_PERIODIK_EXPORT_FAILED,
          title: this.$t('eshop.history_type.payment_abo_periodik_export_failed')
        },
        {
          id: HISTORY_TYPE_PAYMENT_ABO_PERIODIK_PROCESSED,
          title: this.$t('eshop.history_type.payment_abo_periodik_processed')
        },
        {
          id: HISTORY_TYPE_PAYMENT_ABO_PERIODIK_NOT_PROCESSED,
          title: this.$t('eshop.history_type.payment_abo_periodik_not_processed')
        },
        {
          id: HISTORY_TYPE_SUBSCRIPTION_ACTIVATED,
          title: this.$t('eshop.history_type.subscription_activated')
        },
        {
          id: HISTORY_TYPE_NEXT_PAYMENT_SET,
          title: this.$t('eshop.history_type.next_payment_set')
        },
        {
          id: HISTORY_TYPE_SMS_CODE_CREATED,
          title: this.$t('eshop.history_type.sms_code_created')
        },
        {
          id: HISTORY_TYPE_MO_SMS_DELIVERED,
          title: this.$t('eshop.history_type.mo_sms_delivered')
        },
        {
          id: HISTORY_TYPE_MT_SMS_CREATED,
          title: this.$t('eshop.history_type.mt_sms_created')
        },
        {
          id: HISTORY_TYPE_MT_SMS_SENT,
          title: this.$t('eshop.history_type.mt_sms_sent')
        },
        {
          id: HISTORY_TYPE_MT_SMS_SENT_ERROR,
          title: this.$t('eshop.history_type.mt_sms_sent_error')
        },
        {
          id: HISTORY_TYPE_TYPE_MT_SMS_STATUS_DELIVERED,
          title: this.$t('eshop.history_type.mt_sms_status_delivered')
        },
        {
          id: HISTORY_TYPE_PAYMENT_CANCELLED,
          title: this.$t('eshop.history_type.payment_cancelled')
        },
        {
          id: HISTORY_TYPE_PAYMENT_RESULT_SET,
          title: this.$t('eshop.history_type.payment_result_set')
        },
        {
          id: HISTORY_TYPE_CLOSED_SUBSCRIPTION_MAIL_3_DAYS,
          title: this.$t('eshop.history_type.closed_subscription_mail_3_days')
        },
        {
          id: HISTORY_TYPE_CLOSED_SUBSCRIPTION_MAIL_NEXT_DAY,
          title: this.$t('eshop.history_type.closed_subscription_mail_next_day')
        },
        {
          id: HISTORY_TYPE_NEXT_RECURRING_PAYMENT_MAIL,
          title: this.$t('eshop.history_type.next_recurring_payment_mail')
        }
      ]
    }
  },
  methods: {
    historyTypeById (id) {
      var item = id
      this.historyTypeValues.forEach(function (history) {
        if (id === history.id) {
          item = history
        }
      })
      return item
    }
  }
}
